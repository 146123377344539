import { useLocalStorage } from '@vueuse/core';
import { defineStore } from 'pinia';
import { HTTP, FILE_HTTP, X_API_KEY } from '../services/http.js';
import { useApi } from './api.js';

export const useAuthStore = defineStore({
  id: 'auth',
  state: () => ({
    account: useLocalStorage('account', null, {
      serializer: {
        read: (v) => (v ? JSON.parse(v) : null),
        write: (v) => JSON.stringify(v),
      },
    }),
    deviceValid: false,
    mobileNumber: null,
    smsCodeStatus: null,
    smsToken: null,
    commsGuard: false,
    usesTotp: false,
    requiresReauth: false, // if the account has been reset, then the user can get the totp key again
    totpKey: null,
  }),
  getters: {    
    hasPermission(state) {
      return (permission) => {
        return state.account?.roles?.includes(permission);
      };
    },
    loggedIn(state) {
      return !!state.account;
    },
    isInactiveLicence(state) {
      return state.account?.isInactiveLicence;
    },
    isDESNZViewer(state) {
      return state.account?.roles?.includes('DESNZRetrofitDataViewer');
    },
    isOfgemRetrofitDataViewer(state) {
      return state.account?.roles?.includes('OfgemRetrofitDataViewer');
    },
    isSchemeUser(state) {
      return state.account?.roles?.includes('SchemeRetrofitPortalAccess');
    },
    isExternalAuditor(state) {
      return state.account?.roles?.includes('ExternalAuditor');
    },
    isExternalAuditorWithDocumentAccess(state) {
      return state.account?.roles?.includes('ExternalAuditor') && state.account?.roles?.includes('AssessmentDocumentAccess');
    },
  },
  actions: {
    async register(credentials) {
      return HTTP.post('/Register', credentials);
    },
    async totpRegister(credentials) {
      return HTTP.post('/Register/totp', credentials)
    },
    async verifyRegistration(credentials) {
      return HTTP.post('/Register/verify', credentials);
    },
    async verifyTotpRegistration(credentials) {
        return HTTP.post('/Register/verify/totp', credentials)
    },
    async commsLogin(token) {
      try {
        if (!this.commsGuard) {
          this.commsGuard = true;
          if (this.account) {
            try {
              await HTTP.post('/Auth/logout');
            } catch {
              // Do nothing
            } finally {
              this.account = null;
            }
          }
          const result = await HTTP.post(`/Auth/commsplatform?k=${token}`);
          const { data } = result;
          this.account = data.model;
          return data;
        }
      } catch (error) {
        this.account = null;
        if (error?.response?.status === 403) {
          throw new Error('You do not have permission to login.');
        }
        throw error;
      }
    },
    async login(user) {
      try {
        const result = await HTTP.post('/Auth/login', user);
        const { data } = result;
        if (data.model.deviceValid) {
          // After 2fa
          this.account = data.model;
          this.requiresReauth = false;
          this.totpKey = null;
          this.smsCodeStatus = null;
          this.smsToken = null;
          this.usesTotp = false;
        } else {
          // Before 2fa
          this.mobileNumber = data.model.maskedMobile;
          this.smsToken = data.model.accessToken;
          this.smsCodeStatus = 'required';
          this.usesTotp = data.model.usesTotp
          this.requiresReauth = data.model.requiresReauth
          this.totpKey = data.model.totpKey
        }
        return data;
      } catch (error) {
        this.account = null;
        if (error?.response?.status === 403) {
          throw new Error('You do not have permission to login.');
        }
        throw error;
      }
    },
    async logout() {
      try {
        await HTTP.post('/Auth/logout');
      } catch {
        // Do nothing
      } finally {
        this.account = null;
      }
    },
    async switchAccount(accountId) {
      const api = useApi();
      const result = await api.switchAccount(accountId);
      this.refreshSession(); // ensure roles are refreshed
      return result;
    },
    async sendPasswordRecoveryCode(username) {
      return HTTP.post('/ResetPassword/sendcode', { username });
    },
    async sendEmailVerifyCode() {
      return HTTP.post('AuthDevice/sendmobilechangecode');
    },
    async resetPassword(payload) {
      return HTTP.post('/ResetPassword/reset-with-code', payload);
    },
    async refreshSession() {
      if (!this.account?.accountName || !this.account?.refreshToken) {
        throw new Error('Missing account details');
      }
      try {
        const result = await HTTP.post('/Auth/refresh', {
          username: this.account.accountName,
          refreshToken: this.account.refreshToken,
          isIntegrationAccount: this.account.isIntegrationAccount ?? false,
          thirdPartyIntegration: this.account.thirdPartyIntegration,
        });
        this.account = result.data.model;
        return result.data;
      } catch (error) {
        this.logout();
        throw error;
      }
    },
    async sendSmsCode() {
      const result = await HTTP.post('AuthDevice/sendsms');
      this.smsCodeStatus = 'requested';
      return result.data;
    },
    async verifySmsCode(smsCode) {
      const result = await HTTP.post('AuthDevice/verifysms', {
        smsCode,
      });
      if (result.data.isSuccess) {
        this.smsCodeStatus = 'verified';
        return result.data;
      }
      throw new Error('Failed to verify SMS Code.');
    },
    async verifyTotpCode(totpCode) {
        const result = await HTTP.post('AuthDevice/verifytotp', {
            totpCode1: totpCode,
        })
        if (result.data.isSuccess) {
            this.smsCodeStatus = 'verified'
            return result.data
        }
        throw new Error('Failed to verify TOTP Code.')
    },
    async completeReset(emailCode, totpCode1, totpCode2) {
        const result = await HTTP.post('/AuthDevice/completereset', {
            confirmationCode: emailCode,
            totpCode1,
            totpCode2,
        })
        if (result.data.isSuccess) {
            return result.data
        }
        throw new Error('Failed to complete reset.')
    },
    async changeMobileNumber(mobileNumber, mobileChangeCode) {
      const result = await HTTP.post('/AuthDevice/changemobile', {
        mobileNumber,
        mobileChangeCode,
      });
      this.mobileNumber = result.data.maskedMobile || mobileNumber;
      this.smsCodeStatus = 'requested';
      return result.data;
    },
    async useLegacyToken(trustmarkBusinessId, token, trustmarkId, jt) {
      try {
        const result = await HTTP.post(
          `/LegacyLodgement/access/${trustmarkBusinessId}/${token}/login?trustmarkId=${trustmarkId}&jt=${jt}`,
          {
            trustmarkId,
            jt,
          }
        );
        this.account = null;
        if (result.data.isSuccess) {
          this.account = result.data.model;
        }
        return result.data;
      } catch (e) {
        await this.logout();
        return;
      }
    },
  },
});

const addIntercepts = (http, x_api_key) => {
  http.interceptors.request.use((config) => {
    const auth = useAuthStore();
    if (auth.account?.accessToken) {
      config.headers.Authorization = `Bearer ${auth.account.accessToken}`;
    } else if (auth.smsToken) {
      config.headers.Authorization = `Bearer ${auth.smsToken}`;
    }

    if (x_api_key) {
      config.headers['X-Api-Key'] = x_api_key;
    }
    return config;
  });

  // Auth error handler
  http.interceptors.response.use(
    (r) => r,
    async (error) => {
      const originalRequest = error.config;
      if (
        error.response?.status === 401 &&
        !originalRequest.retry &&
        originalRequest.url !== '/Auth/logout' &&
        !originalRequest.url?.toLowerCase().includes('auth/refresh')
      ) {
        // Set retry to true to prevent a loop
        originalRequest.retry = true;
        const auth = useAuthStore();
        try {
          await auth.refreshSession();
          return http(originalRequest);
        } catch {
          // Logout
          auth.logout();
        }
      }
      throw error;
    }
  );
};

addIntercepts(HTTP, X_API_KEY);
addIntercepts(FILE_HTTP);
