<template>
  <form v-if="auditorsRaw" class="d-flex flex-column" @submit.prevent="onCheck">
    <div class="audit-section">
      <div class="">
        <div class="form-label mb-0">Your Reference</div>
        <div class="text-secondary">{{ project.yourProjectReference }}</div>
      </div>
      <div class="mt-3">
        <div class="form-label mb-0">Project Reference</div>
        <div class="text-secondary">{{ project.projectReference }}</div>
      </div>
      <div v-if="project.assessment" class="mt-3">
        <div class="form-label mb-0">Assessment Reference</div>
        <div class="text-secondary">{{ project.assessment.retrofitReference }}</div>
      </div>
      <div class="mt-3">
        <div class="form-label mb-0">Address</div>
        <div class="text-secondary">
          {{ formatAddressSingleLine(project.address) }}
        </div>
      </div>
    </div>
    <div class="audit-section">
      <FormSelect
        label="Audit Type"
        id="select-audit-type"
        :validator="v$.bespokeAuditType"
        v-model="form.bespokeAuditType"
      >
      <option v-for="auditType in app.appData?.bespokeAuditTypes" :value="auditType">{{ auditType }}</option>
      </FormSelect>
      <FormSelect
        label="Assign Auditor"
        id="select-auditor"
        class="mt-4 mb-4"
        :validator="v$.auditor"
        v-model="form.auditor"
        :disabled="!canBookBespokeAudits"
      >
        <option v-if="assignToMe" :value="assignToMe.value">{{ assignToMe.label }}</option>
        <optgroup v-if="auditors?.length" label="Select an Auditor">
          <option v-for="auditor of auditors" :key="auditor.value" :value="auditor.value">
            {{ auditor.label }}
          </option>
        </optgroup>
      </FormSelect>
      <FormMultiSelect
        id="reasonsForAudit"
        label="Current Reasons for Audit"
        :options="app.appData.bespokeAuditReasonTypes[form.bespokeAuditType]"
        :validator="v$.reasonsForAudit"
        v-model="form.reasonsForAudit"
      />
      <button class="mt-2 btn btn-primary btn-sm w-100" type="submit" :disabled="!canBookBespokeAudits">
        Assign
      </button>
      <div v-if="!canBookBespokeAudits" class="text-danger mt-2">
        You do not have permission to assign auditors
      </div>
    </div>
  </form>
  <div v-else class="p-5 text-center">
    <span class="spinner-border" />
  </div>
  <Teleport to="body">
    <Modal
      ref="modalEl"
      title="Confirm Bespoke Auditor Assignment"
      :on-save="onSubmit"
      save-text="Confirm"
    >
      <template #form
        >Are you sure you wish to assign {{ form.auditor }} to conduct a <b>Bespoke Audit</b>?</template
      >
    </Modal>
  </Teleport>
</template>

<script setup>
import { onBeforeMount, ref, computed } from 'vue';
import useVuelidate from '@vuelidate/core';
import { helpers, required } from '@vuelidate/validators';
import { useApi } from '../stores/api';
import { useAuthStore } from '../stores/auth';
import { useAuditStore } from '../stores/audit';
import FormSelect from './FormSelect.vue';
import { useProjectsStore } from '../stores/projects';
import { formatAddressSingleLine } from '../utilities/address';
import Modal, { useModal } from './Modal.vue';
import { useAppStore } from '../stores/app';
import FormMultiSelect from './FormMultiSelect.vue';

const emit = defineEmits(['update']);

const auth = useAuthStore();
const audit = useAuditStore();
const app = useAppStore();
const { project } = useProjectsStore();

const isBespokeAuditor = computed(() => {
  return auth.account?.roles?.indexOf('BespokeAuditor') > -1;
});

const canBookBespokeAudits = computed(() => {
  return auth.account?.roles?.indexOf('BespokeAuditAppointmentBooker') > -1;
});

const form = ref({
  auditor: audit.bespoke.auditor || '',
  bespokeAuditType: audit.bespoke.bespokeAuditType || '',
  reasonsForAudit: audit.bespoke.currentReasonsForAudit || [],
});
const rules = {
  auditor: {
    required: helpers.withMessage('Please select an auditor', required),
  },
  bespokeAuditType: {
    required: helpers.withMessage('Please select an audit type', required),
  },
  reasonsForAudit: {
    required: helpers.withMessage('Please select a reason for audit', required),
  },
};
const v$ = useVuelidate(rules, form, { $stopPropagation: true });

const onSubmit = async () => {
  emit('update', {
    updates: {
      auditor: form.value.auditor,
      bespokeAuditType: form.value.bespokeAuditType,
      priority: 'Normal',
      currentReasonsForAudit: form.value.reasonsForAudit,
      reasonsForAudit: form.value.reasonsForAudit,
    },
  });
};

const auditorsRaw = ref(null);
const assignToMe = computed(() => {
  if (!auditorsRaw.value) return null;
  return auditorsRaw.value
    .filter((auditor) => auditor.accountId === auth.account.accountName)
    .map((auditor) => ({
      label: 'Assign to Me',
      value: auditor.accountId,
    }))?.[0];
});
const auditors = computed(() => {
  if (!auditorsRaw.value) return null;
  return auditorsRaw.value
    .filter((account) => account.accountId !== auth.account.accountName)
    .map((auditor) => ({
      label: auditor.accountId,
      value: auditor.accountId,
    }));
});

const api = useApi();

const { modal, el: modalEl } = useModal();

const onCheck = async () => {
  const valid = await v$.value.$validate();
  if (!valid) return;
  modal.value.show();
};

const init = async () => {
  const result = await api.getBespokeAuditors();
  if (result) {
    auditorsRaw.value = result.model;
  }
};

onBeforeMount(init);
</script>
